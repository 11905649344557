import Typography from '@material-ui/core/Typography';

export default function Footer() {
  return (
    <footer>
      <Typography color="textSecondary">
        <a
          className="footerLink"
          rel="license noopener noreferrer"
          href="https://dhamma-dana.de/datenschutz"
          target="_blank"
        >
          Privacy Policy
        </a>
      </Typography>

      <Typography variant='subtitle2'>
        version: {process.env.REACT_APP_VERSION}
      </Typography>
    </footer>
  );
}
