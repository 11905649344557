import InputAdornment from '@material-ui/core/InputAdornment';
import FilledInput from '@material-ui/core/FilledInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';

import {
  clearInput,
  setSearchResults,
  handleInputChange,
} from '../state';
import { parseReference } from '../utils';
import '../assets/styles.css';

const BOOK_SYNONYMS = {
  'MN': 'M',
  'AN': 'A',
  'DN': 'D'
};

export default function Search() {
  const dispatch = useDispatch();

  const ptsTree = useSelector((state) => state.ptsTree);
  const inputText = useSelector((state) => state.inputText);
  const isError = useSelector((state) => state.isError);
  const errorMessage = useSelector((state) => state.errorMessage);

  function handleSubmit(e) {
    e.preventDefault();

    try {
      const { book: parsedBook, volume, page } = parseReference(inputText);

      let book = typeof BOOK_SYNONYMS[parsedBook] === 'string'
        ? BOOK_SYNONYMS[parsedBook]
        : parsedBook;

      const pagesRange = get(ptsTree, `${book.toLowerCase()}.${volume}`, null)
      const isValidReference = pagesRange &&
        pagesRange['start'] <= page &&
        pagesRange['end'] >= page &&
        pagesRange['exceptions'].indexOf(page) === -1;

      if (isValidReference) {
        return dispatch(setSearchResults(
          book,
          volume,
          page
        ));
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit} className="form">
        <FormControl
          size="medium"
          variant="filled"
          error={isError && Boolean(inputText.length)}
          className="searchFormControl"
        >
          <InputLabel htmlFor="search" classes={{ shrink: 'inputShrink' }}>
            Search
          </InputLabel>
          <FilledInput
            id="search"
            placeholder="ie. D ii 14"
            value={inputText}
            onChange={(e) => dispatch(handleInputChange(e.target.value))}
            aria-describedby="search-text"
            inputProps={{
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'off',
              spellCheck: 'false',
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="clear search input"
                  onClick={() => dispatch(clearInput())}
                  disabled={inputText === ''}
                >
                  <CloseRoundedIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="search-text">
            {isError && inputText ? errorMessage : ' '}
          </FormHelperText>
        </FormControl>
        <div className="submitBox">
          <Button type="submit" disabled={inputText === ''}>
            Convert
          </Button>
        </div>
      </form>
    </>
  );
}
