const ROMAN_TO_INT = {
  'i': 1,
  'ii': 2,
  'iii': 3,
  'iv': 4,
  'v': 5,
  'vi': 6,
  'vii': 7,
  'viii': 8,
  'ix': 9,
  'x': 10
}

export const parseReference = (ref) => {
  let book = '';
  let volume = '';
  let page = '';

  const splitted = ref.split(/[\s.]+/).filter(x => x);


  if (splitted.length === 3) {
    if (!isNaN(parseInt(splitted[2]))) {
      page = parseInt(splitted[2]);

      if (typeof ROMAN_TO_INT[splitted[1].toLowerCase()] === 'number') {
        volume = ROMAN_TO_INT[splitted[1].toLowerCase()];
        book = splitted[0];
      } else {
        page = '';
      }
    }
  }

  if (splitted.length === 2) {
    if (!isNaN(parseInt(splitted[1]))) {
      page = parseInt(splitted[1]);
      volume = 0;
      book = splitted[0];
    }
  }

  return { book, volume, page };
}