import Typography from '@material-ui/core/Typography';

export default function Header() {
  return (
    <header>
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt='logo' />
      <Typography variant="h6" component="h1">
        PTS Converter
      </Typography>
    </header>
  );
}
