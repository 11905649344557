import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { setNavTab } from '../state';

export default function About() {
  const dispatch = useDispatch()

  return (
    <div className='about'>
      <p>
        This is a Beta version and is still under development.
      </p>
      <hr />
      <section>
        <Typography variant='h5'>Usage</Typography>
        <p>
          In order to use this tool you should have a fairly recent browser as well as an internet connection.
        </p>
        <p>
          You can either use the{' '}
          <span className='link' onClick={() => dispatch(setNavTab(0))}>Search</span>
          {' or '}
          <span className='link' onClick={() => dispatch(setNavTab(1))}>Select</span>
          {' '} tab to enter your reference that should look like <code>M i 5</code>, <code>Dhp 34</code>, <code>Bv i. 16</code>,{' '}
          <code>it.80</code>, <code>S.I.42</code> (see {' '}
          <span className='link' onClick={() => dispatch(setNavTab(2))}>Books</span>
          {' '}
          for a list of supported books).
        </p>
        <p>
          If the reference is included in the data it'll show up result cards with links to texts / translations. Sometimes the excerpt of the beginning is also shown to better identify the exact starting point in the linked text.
        </p>
        <p>
          Please note that the texts and positions in the results are only the beginning of the reference. It might very well be that the text you're looking for is not this one, but the next one or one after that.
        </p>
      </section>
      <hr />
      <section>
      </section>
      <section>
        <Typography variant='h5'>Datasources</Typography>

        <p><b>suttacentral.net</b>: Links to root texts and translations.</p>
        <p><b>tipitika.org</b>: Links to root texts.</p>
        <p><b>ancient-buddhist-texts.net</b>: Links to translations of Dhp-A.</p>

        <p><b>Disclaimer:</b> This tool is only meant to be auxiliary in finding a text with only having a PTS reference. The data from the datasources have been processed for this reason. Since the amount of data is too large to verify the complete correctness of the results shown on this website, please try to to cross-check different datasources when possible and use semantic criteria to verify the resolved reference points to the correct text and position. If you find any errors or inconsistencies, please report them via email or open an issue on Gitlab.</p>
      </section>
      <hr />
      <section>
        <Typography variant='h5'>Attribution</Typography>
        <p>
          The inteface is based on the work of
          {' '}
          <a
            href="https://github.com/benmneb/pts-converter"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            benmneb
          </a>
          {' '}
          who gave the original tool a new look, added the text search and dedicated his work to the public domain (
          <a
            className="link"
            rel="license noopener noreferrer"
            href="http://creativecommons.org/publicdomain/zero/1.0/"
            target="_blank"
          >
            CC0
          </a>
          ).
        </p>
      </section>
      <section>
        <p>
          The font used is {' '}
          <a
            className='link'
            rel="noopener noreferrer"
            href='https://rsms.me/inter/'
            target='_blank'
          >
            Inter
          </a>
          {' '} (licensed under {' '}
          <a
            className="link"
            rel="license noopener noreferrer"
            href="https://choosealicense.com/licenses/ofl-1.1/"
            target="_blank"
          >
            SIL Open Font License 1.1
          </a>
          ).
        </p>
      </section>
      <hr />
      <section>
        <Typography variant='h5'>Contact</Typography>
        <p>
          The source code can be found here:
          {' '}
          <a
            href="https://gitlab.com/olastor/pts-converter"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            frontend project
          </a>
          {' '}, {' '}
          <a
            href="https://gitlab.com/olastor/pts-data"
            className="link"
            target="_blank"
            rel="noopener noreferrer"
          >
            backend / data project
          </a>.
        </p>
        <p>
          Please write feedback to <a href='mailto:pts-converter@dhamma-dana.de'>pts-converter@dhamma-dana.de</a>.
        </p>
      </section>
    </div>
  );
}
