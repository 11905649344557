import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import '../assets/styles.css';

export default function ResultCard(props) {
  const { data } = props;
  const {
    datasource,
    textName,
    textId,
    hierarchy,
    edition,
    links
  } = data;

  return (
    <Card className='resultCard'>
      <CardContent>
        {!!edition && edition > 0 && <Typography className='' color="textSecondary" variant='subtitle2' gutterBottom>
          PTS {edition}. Edition
        </Typography>}

        <Typography className='' color="textSecondary" variant='subtitle2' gutterBottom>
          {datasource}
        </Typography>

        {textId && <Typography variant="h6" component="h2">
          {textId.toUpperCase()}
        </Typography>}

        {textName && <Typography variant="h5">
          {textName}
        </Typography>}

        {hierarchy && <Typography variant="subtitle1" color="textSecondary">
          {hierarchy.join(' < ')}
        </Typography>}

        {links && links.map(({ url, authorName, language, excerpt }, i) =>
          <div key={url + i}>
            <hr />
            {authorName && <Typography variant="h6">
              {authorName}
            </Typography>}
            {language && <Typography variant="body2" component="h1">
              {language}
            </Typography>}
            {excerpt && <blockquote>
              {excerpt} ...
            </blockquote>}
            <Link href={url} target='_blank' rel='noreferrer nofollow'>
              <Button size="small">
                Open Text &nbsp;
                <OpenInNewRoundedIcon />
              </Button>
            </Link>
          </div>
        )}
        <hr/>
      </CardContent>
    </Card>
  );
}
