import range from 'lodash/range'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
const initialState = {
  ptsTree: null,
  results: [],
  allBookAbbrevs: [],
  bookNames: null,
  volumesForSelectedBook: [],
  pagesForSelectedVolume: [],
  selectedBook: '',
  selectedVolume: '',
  selectedPage: '',
  navTab: 0,
  inputText: '',
  isError: false,
  errorMessage: null,
  showSnackbar: false,
  deferredInstallPrompt: null,
};

const getAvailablePages = (state, book, volume) => {
  let pages = [];

  const pagesObj = get(state.ptsTree, `${book.toLowerCase()}.${volume}`, null);
  if (!pagesObj) {
    return [];
  }

  const start = get(pagesObj, 'start', 0);
  const end = get(pagesObj, 'end', 0);
  const exceptions = get(pagesObj, 'exceptions', []);

  pages = range(start, end + 1).filter(i => exceptions.indexOf(i) === -1);

  return pages;
};


export function reducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_BOOKS':
      return {
        ...state,
        bookNames: action.data
      };
    case 'SET_NAV_TAB':
      return {
        ...state,
        navTab: action.tab,
        // selectedBook: initialState.selectedBook,
        // selectedVolume: initialState.selectedVolume,
        // selectedPage: initialState.selectedPage,
        // inputText: initialState.inputText,
      };
    case 'SELECT_BOOK': {
      const selectedBook = action.book;

      const volumesForSelectedBook = Object.keys(state.ptsTree[selectedBook.toLowerCase()])
        .map(i => parseInt(i, 10))
        .sort()

      let selectedVolume = initialState.selectedVolume;
      let pagesForSelectedVolume = [];

      if (isEqual(volumesForSelectedBook, [0])) {
        selectedVolume = 0;
        pagesForSelectedVolume = getAvailablePages(state, selectedBook, selectedVolume);
      }

      return {
        ...state,
        selectedBook,
        selectedVolume,
        selectedPage: initialState.selectedPage,
        results: [],
        volumesForSelectedBook,
        pagesForSelectedVolume,
        inputText: initialState.inputText,
      };
    }
    case 'SELECT_VOLUME': {
      const selectedVolume = typeof action.division === 'number'
        ? action.division
        : parseInt(action.division, 10);

      if (isNaN(selectedVolume)) {
        return state;
      }

      const pagesForSelectedVolume = getAvailablePages(
        state,
        state.selectedBook,
        action.division
      )

      return {
        ...state,
        selectedVolume,
        selectedPage: initialState.selectedPage,
        inputText: initialState.inputText,
        results: [],
        pagesForSelectedVolume
      };
    }
    case 'SELECT_PAGE':
      const selectedPage = typeof action.page === 'number'
        ? action.page
        : parseInt(action.page, 10);

      if (isNaN(selectedPage)) {
        return state;
      }

      return {
        ...state,
        selectedPage,
        inputText: initialState.inputText,
        results: [],
      };
    case 'HANDLE_INPUT_CHANGE': {
      return {
        ...state,
        inputText: action.text,
        results: [],
        isError: false,
        selectedBook: initialState.selectedBook,
        selectedVolume: initialState.selectedVolume,
        selectedPage: initialState.selectedPage,
      };
    }
    case 'RESET_INPUT_VALUES':
      return {
        ...state,
        selectedBook: initialState.selectedBook,
        selectedVolume: initialState.selectedVolume,
        selectedPage: initialState.selectedPage,
        results: initialState.results,
      };
    case 'SET_SEARCH_RESULTS': {
      return {
        ...state,
        selectedBook: action.book,
        selectedVolume: action.volume,
        selectedPage: action.page,
        // inputText: action.text,
        isError: false,
      };
    }
    case 'SET_ERROR':
      return {
        ...state,
        isError: true,
        errorMessage: action.message,
      };
    case 'CLEAR_INPUT':
      return {
        ...state,
        inputText: initialState.inputText,
        isError: false,
        selectedBook: initialState.selectedBook,
        selectedVolume: initialState.selectedVolume,
        selectedPage: initialState.selectedPage,
      };
    case 'TOGGLE_SNACKBAR':
      return {
        ...state,
        showSnackbar: !state.showSnackbar,
      };
    case 'SET_DEFERRED_INSTALL_PROMPT':
      return {
        ...state,
        deferredInstallPrompt: action.prompt,
      };
    case 'SET_PTS_TREE':
      const allBookAbbrevs = [...new Set(Object.keys(action.data))].sort()
      let ptsTree = {}
      Object.entries(action.data).forEach(([key, value]) => {
        ptsTree[key.toLowerCase()] = value;
      });

      return {
        ...state,
        ptsTree,
        allBookAbbrevs
      };
    case 'SET_RESULTS':
      return {
        ...state,
        results: action.results
      };
    default:
      return state;
  }
}
