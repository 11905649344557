import FormControl from '@material-ui/core/FormControl';
import MuiSelect from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { useDispatch, useSelector } from 'react-redux';

import { ResponsiveMenuItem } from '.';
import { selectBook, selectDivision, selectPage } from '../state';
import sortBy from 'lodash/sortBy';

const INT_TO_ROMAN = ['', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];

export default function Select() {
  const dispatch = useDispatch();

  const bookNames = useSelector((state) => state.bookNames);
  const volumesForSelectedBook = useSelector((state) => state.volumesForSelectedBook);
  const pagesForSelectedVolume = useSelector((state) => state.pagesForSelectedVolume);
  const book = useSelector((state) => state.selectedBook);
  const volume = useSelector((state) => state.selectedVolume);
  const page = useSelector((state) => state.selectedPage);

  const mobile = useMediaQuery((theme) => theme.breakpoints.only('xs'));

  return (
    <div className="selection">
      <FormControl>
        <InputLabel>Book</InputLabel>
        <MuiSelect
          native={mobile}
          name="selectedBook"
          value={book}
          onChange={(e) => dispatch(selectBook(e.target.value))}
        >
          <ResponsiveMenuItem value="" aria-label="none">
            {!mobile ? <em>None</em> : null}
          </ResponsiveMenuItem>
          {bookNames && sortBy(bookNames, ({ abbrev }) => abbrev)
            .map(({ abbrev }) => (
              <ResponsiveMenuItem value={abbrev} key={abbrev}>
                {abbrev}
              </ResponsiveMenuItem>
            ))}
        </MuiSelect>
      </FormControl>
      {!(volumesForSelectedBook.length === 1 && volumesForSelectedBook[0] === 0) &&
        <FormControl>
          <InputLabel>Volume</InputLabel>
          <MuiSelect
            native={mobile}
            name="selectedDiv"
            value={volume}
            onChange={(e) => dispatch(selectDivision(e.target.value))}
            disabled={!book}
          >
            <ResponsiveMenuItem value="" aria-label="none">
              {!mobile ? <em>None</em> : null}
            </ResponsiveMenuItem>
            {volumesForSelectedBook.map((k) => (
                  <ResponsiveMenuItem value={k} key={k}>
                    {INT_TO_ROMAN[k]}
                  </ResponsiveMenuItem>
                ))
              }
          </MuiSelect>
        </FormControl>}
      <FormControl>
        <InputLabel>Page</InputLabel>
        <MuiSelect
          native={mobile}
          name="selectedNum"
          value={page}
          onChange={(e) => {
            dispatch(selectPage(e.target.value));
          }}
        >
          <ResponsiveMenuItem value="" aria-label="none">
            {!mobile ? <em>None</em> : null}
          </ResponsiveMenuItem>
          {pagesForSelectedVolume
                .map(page => (
                  <ResponsiveMenuItem value={page} key={page}>
                    {page}
                  </ResponsiveMenuItem>
                ))}
        </MuiSelect>
      </FormControl>
    </div>
  );
}
