import {
  selectBook,
  setNavTab
} from '../state';
import sortBy from 'lodash/sortBy';
import '../assets/styles.css';

import { useDispatch, useSelector } from 'react-redux';

export default function Books() {
  const dispatch = useDispatch();

  const bookNames = useSelector((state) => state.bookNames);

  return (
    <div className='book-list'>
      {bookNames && sortBy(bookNames, (({ name }) => name))
        .map(({name, abbrev}) => <div
          onClick={
          () => {
            dispatch(selectBook(abbrev));
            dispatch(setNavTab(1));
          }
        }>
          <div>
            <span title={`Select ${name} / ${abbrev}`}>{name}</span>
          </div>
          <div>
            <span title={`Select ${name} / ${abbrev}`}>{abbrev}</span>
          </div>
        </div>)}
    </div>
  );
}
