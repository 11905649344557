export function setNavTab(tab) {
  return {
    type: 'SET_NAV_TAB',
    tab,
  };
}

export function selectBook(book) {
  return {
    type: 'SELECT_BOOK',
    book,
  };
}

export function selectDivision(division) {
  return {
    type: 'SELECT_VOLUME',
    division,
  };
}

export function selectPage(page) {
  return {
    type: 'SELECT_PAGE',
    page,
  };
}

export function handleInputChange(text) {
  return {
    type: 'HANDLE_INPUT_CHANGE',
    text,
  };
}

export function resetInputValues() {
  return {
    type: 'RESET_INPUT_VALUES',
  };
}

export function setSearchResults(book, volume, page) {
  return {
    type: 'SET_SEARCH_RESULTS',
    book,
    volume,
    page,
  };
}

export function setError(message) {
  return {
    type: 'SET_ERROR',
    message,
  };
}

export function clearInput() {
  return {
    type: 'CLEAR_INPUT',
  };
}

export function toggleSnackbar() {
  return {
    type: 'TOGGLE_SNACKBAR',
  };
}

export function setDeferredInstallPrompt(prompt) {
  return {
    type: 'SET_DEFERRED_INSTALL_PROMPT',
    prompt,
  };
}

export function setPtsTree(data) {
  return {
    type: 'SET_PTS_TREE',
    data,
  };
}

export function setBooks(data) {
  return {
    type: 'SET_BOOKS',
    data,
  };
}

export function setResults(results) {
  return {
    type: 'SET_RESULTS',
    results,
  };
}
