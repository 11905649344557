import { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux';

import { ResultCard } from '../components'
import Typography from '@material-ui/core/Typography';
import * as axios from 'axios'
import { setResults } from '../state';

const INT_TO_ROMAN = ['', 'i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix', 'x'];

export default function Results({ hidden }) {
  const dispatch = useDispatch();
  const book = useSelector((state) => state.selectedBook);
  const volume = useSelector((state) => state.selectedVolume);
  const page = useSelector((state) => state.selectedPage);
  const results = useSelector((state) => state.results);

  useEffect(() => {
    if (
      typeof book === 'string' && book.length > 0 &&
      typeof volume === 'number' && volume >= 0 &&
      typeof page === 'number' && page > 0
    ) {
      axios.get(`${process.env.REACT_APP_API_URL}/lookup/${book.toLowerCase()}/${volume}/${page}.json`)
        .then(res => {
          dispatch(setResults(res.data))
        })
    }
  }, [dispatch, book, volume, page])

  if (hidden) {
    return null;
  }

  return <div>
    {results && results.length > 0 && <div>
      <Typography variant='h6' gutterBottom>
        Showing results for {book} {INT_TO_ROMAN[volume]} {page}:
      </Typography>
      {results.map((data, i) => <ResultCard key={`result-${i}`} data={data} />)}
    </div>}
  </div>
}
