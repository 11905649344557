import { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import SwipeableViews from 'react-swipeable-views';

import { useSelector, useDispatch } from 'react-redux';

import { setNavTab, setPtsTree, setBooks } from '../state';

import { TabContainer, Results, Search, Select, Books, About } from '../components';

import * as axios from 'axios';

export default function Main() {
  const dispatch = useDispatch();

  const tabValue = useSelector((state) => state.navTab);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/structure.json`)
      .then(res => {
        dispatch(setPtsTree(res.data))
      });

    axios.get(`${process.env.REACT_APP_API_URL}/books.json`)
      .then(res => {
        dispatch(setBooks(res.data))
      });
  }, [dispatch]);

  return (
    <>
      <Card className="tabsWrapper" component="main">
        <SwipeableViews
          index={tabValue}
          onChangeIndex={(value) => dispatch(setNavTab(value))}
        >
          <TabContainer index={0} value={tabValue}>
            <Typography paragraph component="h2">
              Please type or copy & paste the PTS reference you want to convert.
            </Typography>
            <Search />
          </TabContainer>
          <TabContainer index={1} value={tabValue}>
            <Typography paragraph component="h2">
              Please select the PTS reference you want to convert.
            </Typography>
            <Select />
          </TabContainer>
          <TabContainer index={2} value={tabValue}>
            <Books />
          </TabContainer>
          <TabContainer index={3} value={tabValue}>
            <About />
          </TabContainer>
        </SwipeableViews>
      </Card>
      <Results hidden={tabValue !== 0 && tabValue !== 1} />
    </>
  );
}
